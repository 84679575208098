import Layout from "../components/layout";
import React from "react";
import Seo from "../components/seo";

const NotFoundPage = (props) => {
  const { settings } = props.pageContext;
  return (
    <Layout
      logo={settings.logo}
      header={settings.header}
      footer={settings.footer}
    >
      <Seo title="404: Not found" />
      <div className="flex justify-center my-16">
        <h1>NOT FOUND</h1>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
